<template>
    <modal ref="modalAnadirImagen" titulo="Añadir Imagen" no-aceptar adicional="Guardar" :cargado-modal="loading" @adicional="guardar">
        <ValidationObserver ref="form" class="w-100">
            <div class="row mx-0 justify-center">
                <div class="col-10">
                    <p class="text-general f-13 pl-3">Nombre de la imagen</p>
                    <ValidationProvider v-slot="{ errors }" rules="required|max:80" class="w-100" name="nombre">
                        <el-input v-model="nombre_publicidad" class="w-100" />
                        <p class="f-11 text-danger">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>
                <div class="col-10 mt-4">
                    <p class="text-general f-13 pl-3">Seleccionar carpeta a guardar</p>
                    <ValidationProvider v-slot="{ errors }" rules="required" class="w-100" name="carpeta">
                        <el-select v-model="id_carpeta" placeholder="Seleccionar carpeta" class="w-100">
                            <el-option
                            v-for="item in carpetas"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                        <p class="f-11 text-danger">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>
                <div class="col-10 mt-4">
                    <slim-cropper
                    ref="imgAnuncio"
                    :options="slimOptions"
                    class="border cr-pointer publicidad-picture"
                    />
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Publicidad from "~/services/publicidad/publicidad_admin";
export default {
    data(){
        return {
            nombre_publicidad: "",
            id_carpeta: null,
            id: null,
            carpetas: [],
            slimOptions: {
                label: "Agregar Imagen",
                buttonCancelLabel: "Cancelar",
                buttonConfirmLabel: "ok",
            },
            loading:false,
            tipo: 1, // 1 Cliente 2 Vendedor
            id_cedis: null,
        };
    },
    methods: {
        toggle(){

            this.$refs.modalAnadirImagen.toggle();
        },
        async listarCarpetas(){
            try {
                const { data } = await Publicidad.getCarpetas({tipo: this.tipo});
                this.carpetas = data.carpetas;
            } catch (e){
                this.error_catch(e);
            }
        },
        addImgCliente({ anuncio, id_cedis, tipo }){
            this.tipo = tipo;
            this.id_cedis = id_cedis;
            this.listarCarpetas();
            this.id = anuncio.id
            this.nombre_publicidad = anuncio.titulo
            this.id_carpeta = anuncio.id_carpeta
            if (anuncio.imagen !== null){
                this.$refs.imgAnuncio.set_image(`${anuncio.imagen_firmada}`)
            } else {
                this.$refs.imgAnuncio.instanciaCrop.remove()
            }
            this.$refs.modalAnadirImagen.toggle();
        },
        async guardar(){
            try {
                const valid = await this.$refs.form.validate()
                if(!valid) return
                this.loading = true
                let model = {}

                const archivo = this.$refs.imgAnuncio.instanciaCrop.dataBase64.output.image
                if(!archivo){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                model.imagen = archivo
                model.titulo = this.nombre_publicidad
                model.id_carpeta = this.id_carpeta
                model.tipo = this.tipo
                model.id_cedis = this.id_cedis
                model.id = this.id

                const {data} = await Publicidad.editarPublicidad(model)
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.$emit('update', data.model)
                this.$refs.modalAnadirImagen.toggle();

            } catch (e){
                this.error_catch(e);
            } finally {
                this.loading = false
            }
        },
        limpiar(){
            this.nombre_publicidad = ""
            this.id_carpeta = null,
            this.id_cedis = null,
            this.$refs.imgAnuncio.instanciaCrop.remove()
        }
    },
};
</script>
<style lang="scss" scoped>
.publicidad-picture {
  width: 100%;
  height: 250px;
}
</style>
